<script>  

</script>

<style>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;

    z-index: 100;

    overflow: auto;
  }

  .content {
    padding: 1rem;
  }
</style>

<div class="modal">
  <div class="header flex justify-between">
    <div class="text-2xl pl-2">Edit</div>
    <div on:click class="text-2xl text-red-500 pr-2">X</div>
  </div>
  <hr>
  <div class="content">
    <slot/>
  </div>
</div>
